<template>
  <div class="forget">
      <Pagenationtopimg />
      <Pagenationtop :pagenation_title="pagenation_title" path="forget"/>
      <div class="main-container">
        <div class="content-container">
          <div class="top-title">
            <div class="canvas"></div>
            <p>我們將發送手機驗證碼至您的手機內</p>
          </div>
          <div class="content one">
            <span class="ps-title"><span class="after">*</span>驗證手機</span>
            <div class="sub-container"></div>
            <input v-model="user.account" class="input" type="text" placeholder="請輸入手機號碼">
            <v-btn :disabled="sms_disabled" @click="phoneSMS">
              {{sms_button_text}}
            </v-btn>
          </div>
          <div class="content two">
            <span class="ps-title"><span class="after">*</span>簡訊驗證</span>
            <input v-model="user.sms_code" class="input" placeholder="請輸入驗證碼" type="text">
          </div>
          <div class="content">
            <span class="ps-title"><span class="after">*</span>設定新密碼</span>
            <div class="password-container" v-if="password_eye">
                <input class="input" v-model="user.password" type="text" @keyup.enter="Login" placeholder="請輸入6-20碼英數字">
                <i @click="eyeStatus" class="fas fa-eye"></i>
            </div>
            <div class="password-container" v-else>
                <input class="input" v-model="user.password" type="password" @keyup.enter="Login" placeholder="請輸入6-20碼英數字">
                <i @click="eyeStatus" class="fas fa-eye-slash"></i>
            </div>
          </div>
          <div class="content">
            <span class="ps-title"><span class="after">*</span>確認新密碼</span>
            <div class="password-container" v-if="password_eye">
                <input class="input" v-model="user.password2" type="text" @keyup.enter="Login" placeholder="請輸入6-20碼英數字">
                <i @click="eyeStatus" class="fas fa-eye"></i>
            </div>
            <div class="password-container" v-else>
                <input class="input" v-model="user.password2" type="password" @keyup.enter="Login" placeholder="請輸入6-20碼英數字">
                <i @click="eyeStatus" class="fas fa-eye-slash"></i>
            </div>
          </div>
          <div class="content number">
            <span class="ps-title"><span class="after">*</span>驗證碼</span>
            <div class="sub-container">
              <input v-model="user.acode" type="text" class="input" placeholder="請輸入驗證碼">
              <div class="ps-container">
                <div class="number">
                  <img :src="captcha_img" alt="">
                </div>
                <button>
                  <img src="../../../public/img/creatmember/replay.png" alt="">
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="check">
          <button 
          @click="editPassword">
              確定
          </button>
        </div>
      </div>
  </div>
</template>

<script>
import Pagenationtopimg from '../../components/pagenationimg/index.vue'
import Pagenationtop from "../../components/pagenationtop/index.vue"
import qs from 'qs'

export default {
  data(){
    return{
      password_eye:false,//密碼顯示
      sms_button_text:'取得驗證碼',
      mask_time:300,//驗證碼時間
      pagenation_title:[
        '忘記密碼'
      ],
      user:{
        sms_code:null,
        account:null,
        acode:null,
        password:null,
        password2:null,
      },
      captcha_img:null,
      info:null,
      sms_disabled:false
    }
  },
  components:{
    Pagenationtopimg,
    Pagenationtop,
  },
  props:[
    'closeLogin',
    'closeDearlerLogin'
  ],
  mounted(){
      let vm = this
      if(this.$session.get('mask_time')){
        console.log('masktime')
            vm.masktimeout = setInterval(this.maskTime, 1000),
            vm.timeout = setTimeout(this.timeUp, 60000),
            vm.sms_button_text = "驗證碼重發("+vm.mask_time+")",
            vm.sms_disabled = true
      }
      this.closeDearlerLogin()
      this.closeLogin()
      this.getCaptcha()
      this.$store.commit('closeOverlay')
  },
  methods:{
    //手機驗證碼
    phoneSMS(){
      let vm = this
      if(vm.user.account == null){
        alert('請輸入手機號碼')
        vm.$refs.phone.focus()
      }
      else{
        vm.axios.post('/api/member/to_phoneSMS', qs.stringify({
          phone: vm.user.account
        }))
        .then( function(res){
          console.log(res)
          if(res.data.status){
            this.$session.set('mask_time',true)
            vm.masktimeout = setInterval(this.maskTime, 1000),
            vm.timeout = setTimeout(this.timeUp, 60000),
            vm.sms_button_text = "驗證碼重發("+vm.mask_time+")",
            vm.sms_disabled = true
          }
          else{
            alert(res.data.msg)
          }
        })
      }
    },
    maskTime(){
      console.log(123)
      this.mask_time = this.mask_time-1
      this.sms_button_text = "驗證碼重發("+this.mask_time+")"
    },
    timeUp(){
      this.sms_button_text = "取得驗證碼"
      this.sms_disabled = false
      this.$session.remove('mask_time')
      clearTimeout(this.timeout)
      clearTimeout(this.masktimeout)
    },
    editPassword(){
      this.axios.post('/api/member/forgetpassword', qs.stringify({
        lang: this.$store.state.lang,
        account:this.user.account,
        acode:this.user.acode,
        password:this.user.password,
        password2:this.user.password2,
      }),
      )
      .then(response => (
        this.info=response,
        alert('密碼修改成功'),
        this.$store.commit('activeLogin')
      ))
      .catch(function (error){
        alert('密碼修改失敗')
        console.log(error)
      })
    },
    getCaptcha(){
      this.axios.post('/api/member/captcha_show')
      .then(response => (
        this.captcha_img = response.data.message,
        this.info = response.data))
      .catch(function (error){
        console.log(error)
      })
    },
    //密碼顯示status
    eyeStatus(){
        this.password_eye = !this.password_eye
    }
    
  }
}
</script>
